import { headerButton } from '@/config/headerConfig';
import { Dialog, Transition } from '@headlessui/react';
import { Bars3Icon, ChevronLeftIcon } from '@heroicons/react/24/outline';
import { signIn, signOut, useSession } from 'next-auth/react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { ParsedUrlQuery } from 'querystring';
import { Fragment, useEffect, useState } from 'react';
import { HeadButton } from 'ui/HeadButton';
import { Header } from 'ui/Header';

export default function AppHeader() {
  const { data: session } = useSession();
  const [open, setOpen] = useState(false);
  const router = useRouter();
  const showHeader = ['/', '/kpi-builder'].includes(router.pathname) ? false : true;
  const [base, setBase] = useState<string | undefined>(undefined);

  useEffect(() => {
    headerButton.forEach((button) => {
      if (router.pathname.includes(button.href)) {
        setBase(button.base);
      }
    });
  }, [router.pathname]);

  const delAttr = (obj: ParsedUrlQuery, attr: string) => {
    const newObj = new Map(Object.entries(obj));
    newObj.delete(attr);
    return Object.fromEntries(newObj) as Record<string, string>;
  };

  return (
    <>
      <Header
        title={''}
        is_authenticated={session ? true : false}
        email={session?.user.email}
        username={session?.user.username}
        name={session?.user.name}
        login={!session ? () => signIn('auth0') : undefined}
        logout={session ? () => signOut() : undefined}
        mobileMenu={<Bars3Icon className="h-6 w-6" aria-hidden="true" />}
        openMobileMenu={() => { setOpen(true); }}
        logo={'opco-ocapiat.png'}
      >
        <>
          <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-40 lg:hidden" onClose={setOpen}>
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 z-40 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                    <div className="flex px-4 pt-5 pb-2">
                      <Image
                        src={'/logo-komete.svg'} //Default to Komète logo
                        alt=""
                        width={100}
                        height={100}
                      />
                      <button
                        type="button"
                        className="-m-2 inline-flex mr-0 ml-auto items-center justify-center rounded-md p-2 text-gray-400"
                        onClick={() => { setOpen(false); }}
                      >
                        <span className="sr-only">Close menu</span>
                        <ChevronLeftIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div>
                      {showHeader &&
                        headerButton.map((button) => {
                          return (
                            <Link
                              href={{ pathname: button.href, query: router.query }}
                              key={button.href}
                            >
                              <div className="p-2">
                                <button
                                  className={`group relative flex w-full justify-center py-3 rounded text-sm space-x-2 font-medium hover:text-oca-orange-100 hover:bg-red-50 ${router.pathname.includes(button.href)
                                    ? 'text-white bg-orange-500'
                                    : 'text-oca-blue-100 bg-oca-gray-100'
                                    }`}
                                >
                                  <Image
                                    className="lg:mr-2 place-self-center w-4 h-4"
                                    src={router.pathname.includes(button.href)
                                      ? button.focusIcon : button.icon}
                                    alt={button.icon}
                                    width={14}
                                    height={14}
                                  ></Image>
                                  <span className="text-xs lg:text-sm">{button.label}</span>
                                </button>
                              </div>
                            </Link>
                          );
                        })}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
          <div className="hidden space-x-2 md:flex">
            {showHeader &&
              headerButton.map((button) => {
                return (
                  <Link
                    href={{
                      pathname: button.href,
                      query: button.base !== base ? delAttr(router.query, 'annee') : router.query,
                    }}
                    key={button.href}
                  >
                    <HeadButton
                      label={button.label}
                      selected={router.pathname.includes(button.href)}
                      icon={button.icon}
                      focusIcon={button.focusIcon}
                      title={button.title}
                      className={`
                        py-1.5 px-4 rounded text-sm font-medium hover:text-oca-orange-100 hover:bg-red-50 
                        ${router.pathname.includes(button.href)
                          ? 'text-white bg-oca-orange-100'
                          : 'text-oca-blue-100 bg-oca-gray-100'
                        }`}
                    />
                  </Link>
                );
              })}
          </div>
        </>
      </Header>
    </>
  );
}
