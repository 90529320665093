export const headerButton = [
  {
    label: '',
    href: '/accueil',
    icon: '/icons/home-blue.svg',
    focusIcon: '/icons/home.svg',
    title: 'Accueil',
  },
  {
    label: 'Chiffres clés',
    href: '/chiffres-cles',
    icon: '/icons/pie-blue.svg',
    focusIcon: '/icons/pie.svg',
    title: 'Chiffres clés',
  },
  {
    label: 'Économie',
    href: '/economie',
    icon: '/icons/pie-blue.svg',
    focusIcon: '/icons/pie.svg',
    title: 'Économie',
    base: 'entreprises',
  },
  {
    label: 'Salariés',
    href: '/salaries',
    icon: '/icons/group-blue-stroke.svg',
    focusIcon: '/icons/group-white-stroke.svg',
    title: 'Salariés',
    base: 'salaries',
  },
  {
    label: 'Emploi',
    href: '/emploi',
    icon: '/icons/ticket-blue.svg',
    focusIcon: '/icons/ticket.svg',
    title: 'Emploi',
    base: 'salaries',
  },
  {
    label: 'Formation',
    href: '/formation',
    icon: '/icons/ticket-blue.svg',
    focusIcon: '/icons/ticket.svg',
    title: 'Formation',
    base: 'formation_continue',
  },
];
